<template>
  <span class="ch-notification-icon">
    <i class="ch-icons-notification ch-notification-icon__icon" @click="viewNotifications"></i>
    <span v-if="hasUnseenNotification" class="ch-new-content-indicator"> </span>
  </span>
</template>

<script>
import ListNotifications from '@/gql/notifications/ListNotifications.gql';

export default {
  apollo: {
    notifications: {
      query: ListNotifications,
      variables() {
        return {};
      },
    },
  },
  mounted() {
    setInterval(() => {
      this.$apollo.queries.notifications.refetch();
    }, 10 * 1000);

    // retain the event listener for 'resume'
    document.addEventListener('resume', () => {
      this.$apollo.queries.notifications.refetch();
    });
  },
  methods: {
    viewNotifications() {
      this.$router.push({ name: 'Notifications' });
    },
  },
  data() {
    return {
      lastSeenNotificationTime: localStorage.lastSeenNotificationTime,
    };
  },
  computed: {
    hasUnseenNotification() {
      let newNotification = false;
      const filteredNotifications =
        this.notifications && this.notifications.filter((n) => n.itemCount).slice(0, 5);
      if (filteredNotifications) {
        filteredNotifications.forEach((data) => {
          if (new Date(data.updatedAt).getTime() > this.lastSeenNotificationTime) {
            newNotification = true;
          }
        });
      }
      return newNotification;
    },
  },
};
</script>

<style scoped>
.ch-notification-icon {
  position: relative;
}
.ch-notification-icon__icon {
  font-size: 26px;
  color: var(--cs-gray-05);
  padding: 5px;
  line-height: 24px;
}
.ch-new-content-indicator {
  position: absolute;
  top: 0;
  right: 6px;
  width: 10px;
  height: 10px;
  background: var(--cs-primary-base);
  border-radius: 25px;
}
</style>
